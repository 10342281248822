<template>
	<div class="administrativo_usuarios">
		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs6>
				<h2 class="indigo--text text--darken-4">Usuários</h2>
			</v-flex>
			<v-flex xs6 class="text-end">
				<v-dialog scrollable v-model="showDialogCadastro" max-width="700">
					<template v-slot:activator="{ on, attrs }">
						<v-btn depressed dark color="indigo darken-4" v-bind="attrs" v-on="on">
							<v-icon left>add</v-icon>
							<span>Novo Usuário</span>
						</v-btn>
					</template>
					<v-card height="fit-content">
						<v-system-bar color="blue darken-4"></v-system-bar>

						<v-card-title class="headline grey--text text--darken-2">Cadastrar novo usuário</v-card-title>

						<v-card-text>
							<v-form class="my-3" ref="formCadastramento">
								<v-row>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-card-text-outline" label="Nome" v-model="cadastro.nome" :rules="rules.inputTextValidate" counter maxlength="150"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-smart-card-outline" label="CPF" :type="'number'" v-model="cadastro.cpf" :rules="rules.inputCpf" counter maxlength="11"></v-text-field>
									</v-col>
									<v-col cols="6">
										<v-autocomplete prepend-icon="mdi-account-tie" label="Cargo" v-model="cadastro.id_cargo" :items="cargosParaCriacao" item-text="desc_cargo" item-value="id_cargo" :rules="rules.selectValidate" clearable></v-autocomplete>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-account-key" label="Username (usado no login)" v-model="cadastro.username" :rules="rules.inputTextValidate" counter maxlength="30"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-autocomplete prepend-icon="mdi-office-building" label="Unidades" v-model="cadastro.ids_unidades" :items="data.listagemUnidades" item-text="nome_fantasia" item-value="id_unidade" clearable multiple></v-autocomplete>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12">
										<v-select prepend-icon="mdi-account" label="Registrar como Colaborador?" v-model="cadastro.registrar_colaborador" :items="['Não', 'Sim']"></v-select>
									</v-col>
								</v-row>
								<v-row v-if="cadastro.registrar_colaborador == 'Sim'">
									<v-col cols="6">
										<v-autocomplete prepend-icon="mdi-account-tie" label="Cargo Operacional" v-model="cadastro.id_cargo_colaborador" :items="data.listagemCargosColaboradores" item-text="desc_cargo_colaborador" item-value="id_cargo_colaborador" :rules="rules.selectValidate" clearable></v-autocomplete>
									</v-col>
									<v-col cols="6">
										<v-text-field prepend-icon="mdi-currency-brl" label="Salário" v-model="cadastro.salario" :type="'number'" step="0.01" :rules="rules.inputTextValidate"></v-text-field>
									</v-col>
								</v-row>
							</v-form>
						</v-card-text>

						<v-card-actions class="my-5">
							<v-btn depressed dark color="blue darken-4" @click="showDialogCadastro = !showDialogCadastro">
								<v-icon left>close</v-icon>
								<span>Cancelar</span>
							</v-btn>
							<v-btn depressed dark color="indigo darken-4" @click="submitCadastro" :loading="loadingStatus">
								<v-icon left>save</v-icon>
								<span>Salvar</span>
							</v-btn>
						</v-card-actions>

						<v-system-bar color="blue-grey darken-1"></v-system-bar>
					</v-card>
				</v-dialog>
			</v-flex>
		</v-layout>

		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs12>
				<v-layout row wrap class="my-3 mx-0 pa-3 grey lighten-4 rounded-lg">
					<v-flex xs12 class="pr-3">
						<h5>Filtros Gerais</h5>
					</v-flex>
					<v-flex xs3 class="pr-3">
						<v-text-field label="Nome" v-model="filters.nome" outlined dense clearable></v-text-field>
					</v-flex>
					<v-flex xs3 class="pr-3">
						<v-text-field label="CPF" v-model="filters.cpf" outlined dense counter clearable maxlength="11"></v-text-field>
					</v-flex>
					<v-flex xs3 class="pr-3">
						<v-autocomplete label="Cargo" v-model="filters.desc_cargo" :items="data.listagemCargos" item-text="desc_cargo" item-value="desc_cargo" outlined dense clearable></v-autocomplete>
					</v-flex>
					
					<v-flex xs12 class="text-end">
						<v-btn depressed dark color="indigo darken-4" @click="limparFiltro">
							<v-icon left>clear_all</v-icon>
							<span>Limpar filtro</span>
						</v-btn>
					</v-flex>
				</v-layout>
			</v-flex>

			<v-flex xs12>
				<v-data-table
					:headers="listagemHeaders"
					:items="data.listagemGeral"
					:footer-props="{'items-per-page-options': [5, 10, 20, 50, 100]}"
					class="grey lighten-4 grey--text text--darken-2"
					style="white-space:nowrap;"
				>
					<template v-slot:item.actions="{ item }">
						<v-menu offset-y v-if="session.id_cargo_usuario <= item.id_cargo">
							<template v-slot:activator="{ on, attrs }">
								<v-btn depressed dark color="indigo darken-4" class="rounded-circle" style="width: 36px !important; min-width: 36px !important; height: 36px;" v-bind="attrs" v-on="on">
									<v-icon>menu</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item>
									<router-link to="">
										<v-list-item-title class="grey--text text--darken-2" @click="editarUsuario(item)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-pencil</v-icon> Editar
										</v-list-item-title>
									</router-link>
								</v-list-item>
								<v-list-item>
									<router-link to="">
										<v-list-item-title class="grey--text text--darken-2" @click="confirmaResetSenha(item.id_usuario)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-lock-reset</v-icon> Resetar senha
										</v-list-item-title>
									</router-link>
								</v-list-item>
								<v-list-item v-if="item.status == 'Ativo'">
									<router-link to="">
										<v-list-item-title class="grey--text text--darken-2" @click="confirmaInativacaoUsuario(item.id_usuario)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-account-cancel</v-icon> Inativar usuário
										</v-list-item-title>
									</router-link>
								</v-list-item>
								<v-list-item v-if="item.status == 'Inativo'">
									<router-link to="">
										<v-list-item-title class="grey--text text--darken-2" @click="confirmaAtivacaoUsuario(item.id_usuario)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-account-plus</v-icon> Ativar usuário
										</v-list-item-title>
									</router-link>
								</v-list-item>
								<v-list-item v-if="item.id_colaborador == null">
									<router-link to="">
										<v-list-item-title class="grey--text text--darken-2" @click="cadastrarColaborador(item)">
											<v-icon color="indigo darken-4" class="mr-2">mdi-import</v-icon> Registrar como Colaborador
										</v-list-item-title>
									</router-link>
								</v-list-item>
							</v-list>
						</v-menu>
						<v-btn depressed color="indigo darken-4" class="rounded-circle" :disabled="true" style="width: 36px !important; min-width: 36px !important; height: 36px;"v-else>
							<v-icon>menu</v-icon>
						</v-btn>
					</template>
					<template v-slot:item.status="{ item }">
						<v-chip class="ma-2 white--text text--white" :color="item.status == 'Ativo' ? 'success' : 'blue-grey lighten-3'">{{item.status}}</v-chip>
					</template>
					<template v-slot:item.id_colaborador="{ item }">
						<v-chip class="ma-2 white--text text--white" :color="item.id_colaborador != null ? 'success' : 'info'">{{(item.id_colaborador != null) ? 'Sim' : 'Não'}}</v-chip>
					</template>
					<template v-slot:item.dt_criacao="{ item }">
						{{ (item.dt_criacao) ? new Date(item.dt_criacao).toLocaleDateString("pt-BR") : '' }}
					</template>
					<template v-slot:item.dt_alteracao="{ item }">
						{{ (item.dt_alteracao) ? new Date(item.dt_alteracao).toLocaleDateString("pt-BR") : '' }}
					</template>
				</v-data-table>
				<v-flex xs12 class="text-end">
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn depressed dark color="indigo darken-4" class="my-5" :loading="loadingStatusExport" v-bind="attrs" v-on="on">
								<v-icon>file_download</v-icon>
								<span>Exportar planilha</span>
							</v-btn>
						</template>
						<v-list>
							<v-list-item>
								<router-link to="#">
									<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemGeral()">
										<v-icon color="indigo darken-4" class="mr-2">mdi-playlist-check</v-icon> Completa
									</v-list-item-title>
								</router-link>
							</v-list-item>
							<v-list-item>
								<router-link to="#">
									<v-list-item-title class="grey--text text--darken-2" @click="exportarListagemFiltros()">
										<v-icon color="indigo darken-4" class="mr-2">mdi-filter-outline</v-icon> Com filtros
									</v-list-item-title>
								</router-link>
							</v-list-item>
						</v-list>
					</v-menu>
				</v-flex>
			</v-flex>
		</v-layout>

		<v-dialog v-model="showDialogEdit" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Editar Usuário {{dadosEdicao.desc_cargo}}</v-card-title>

				<v-card-text>
					<v-alert border="left" colored-border prominent text type="info">
						Caso o usuário tiver registro de colaborador, também serão atualizados Nome e CPF.
					</v-alert>
					<v-form class="my-3" ref="formEdicao">
						<v-row>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-card-text-outline" label="Nome" v-model="dadosEdicao.nome" :rules="rules.inputTextValidate" counter maxlength="150"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-smart-card-outline" label="CPF" :type="'number'" v-model="dadosEdicao.cpf" :rules="rules.inputCpf" counter maxlength="11"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-account-key" label="Username (usado no login)" v-model="dadosEdicao.username" :rules="rules.inputTextValidate" counter maxlength="30"></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-autocomplete prepend-icon="mdi-account-tie" label="Cargo" v-model="dadosEdicao.id_cargo" :items="cargosParaCriacao" item-text="desc_cargo" item-value="id_cargo" :rules="rules.selectValidate" clearable></v-autocomplete>
							</v-col>
							<v-col cols="12">
								<v-autocomplete prepend-icon="mdi-office-building" label="Unidades" v-model="dadosEdicao.ids_unidades" :items="data.listagemUnidades" item-text="nome_fantasia" item-value="id_unidade" clearable multiple></v-autocomplete>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="my-5">
					<v-btn depressed dark color="blue darken-4" @click="showDialogEdit = !showDialogEdit">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="submitEdicao" :loading="loadingStatus">
						<v-icon left>save</v-icon>
						<span>Salvar</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showDialogConfirmReset" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Confirma reset de senha deste usuario?</v-card-title>

				<v-card-text>
					<v-alert border="left" colored-border prominent text type="info">
						A senha deste usuário será resetada para 1234. Não pode ser desfeito.
					</v-alert>
				</v-card-text>

				<v-card-actions>
					<v-btn depressed dark color="blue darken-4" @click="showDialogConfirmReset = !showDialogConfirmReset">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="efetuarResetSenhaUsuario()" :loading="loadingStatus">
						<v-icon left>mdi-lock-reset</v-icon>
						<span>Resetar</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showDialogConfirmInativacao" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Confirma a inativação deste usuario?</v-card-title>

				<v-card-text>
					<v-alert border="left" colored-border prominent text type="info">
						Este usuário não poderá mais efetuar login na ferramenta. Todo o histórico de registros será mantido.
					</v-alert>
				</v-card-text>

				<v-card-actions>
					<v-btn depressed dark color="blue darken-4" @click="showDialogConfirmInativacao = !showDialogConfirmInativacao">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="efetuarInativacaoUsuario()" :loading="loadingStatus">
						<v-icon left>mdi-cancel</v-icon>
						<span>Inativar</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showDialogConfirmAtivacao" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Confirma a ativação deste usuario?</v-card-title>

				<v-card-text>
					<v-alert border="left" colored-border prominent text type="info">
						Este usuário passará a efetuar login na ferramenta.
					</v-alert>
				</v-card-text>

				<v-card-actions>
					<v-btn depressed dark color="blue darken-4" @click="showDialogConfirmAtivacao = !showDialogConfirmAtivacao">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="efetuarAtivacaoUsuario()" :loading="loadingStatus">
						<v-icon left>mdi-check-all</v-icon>
						<span>Ativar</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showDialogColaborador" max-width="600px">
			<v-card>
				<v-system-bar color="blue darken-4"></v-system-bar>

				<v-card-title class="headline grey--text text--darken-2">Cadatrar usuário como colaborador</v-card-title>

				<v-card-text>
					<v-form class="my-3" ref="formCadastramentoColaborador">
						<v-row>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-card-text-outline" label="Nome" v-model="dadosColaborador.nome" counter maxlength="150" disabled></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-smart-card-outline" label="CPF" :type="'number'" v-model="dadosColaborador.cpf" counter maxlength="11" disabled></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="6">
								<v-autocomplete prepend-icon="mdi-account-tie" label="Cargo Operacional" v-model="dadosColaborador.id_cargo_colaborador" :items="data.listagemCargosColaboradores" item-text="desc_cargo_colaborador" item-value="id_cargo_colaborador" :rules="rules.selectValidate" clearable></v-autocomplete>
							</v-col>
							<v-col cols="6">
								<v-text-field prepend-icon="mdi-currency-brl" label="Salário" v-model="dadosColaborador.salario" :type="'number'" step="0.01" :rules="rules.inputTextValidate"></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions>
					<v-btn depressed dark color="blue darken-4" @click="showDialogColaborador = !showDialogColaborador">
						<v-icon left>close</v-icon>
						<span>Cancelar</span>
					</v-btn>
					<v-btn depressed dark color="indigo darken-4" @click="efetuarCadastroUsuarioComoColaborador()" :loading="loadingStatus">
						<v-icon left>save</v-icon>
						<span>Cadastrar</span>
					</v-btn>
				</v-card-actions>

				<v-system-bar color="blue-grey darken-1"></v-system-bar>
			</v-card>
		</v-dialog>

		<v-snackbar v-model="snackbarShower" :timeout="3000" top :color="snackbarColor">
			{{snackbarText}}
			<template v-slot:action="{ attrs }">
				<v-btn v-bind="attrs" dark fab depressed small :color="snackbarColor" @click="snackbarShower = false">
					<v-icon small>close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
<script>
import Excel from 'exceljs';
export default {
	data() {
		return {
			session: {
				id_cargo_usuario: Number(localStorage.getItem('id_cargo_usuario'))
			},
			cadastro: {
				nome: '',
				cpf: '',
				id_cargo: '',
				username: '',
				ids_unidades: [],
				registrar_colaborador: 'Não',
				salario: 0,
				id_cargo_colaborador: ''
			},
			rules: {
				inputCpf: [
					v => v != '' || 'Campo obrigatório',
					v => v.length == 11 || 'Exatamente 11 caracteres, preenchidos com zeros a esquerda'
				],
				inputTextValidate: [
					v => v != '' || 'Campo obrigatório',
					v => v.length > 0 || 'Campo obrigatório'
				],
				selectValidate: [
					v => v != '' || 'Campo obrigatório',
					v => v != null || 'Campo obrigatório'
				]
			},
			data: {
				listagemGeral: [{cnpj: 'Buscando...'}],
				listagemCargos: [{desc_cargo: 'Buscando...'}],
				listagemCargosColaboradores: [{desc_cargo_colaborador: 'Buscando...'}],
				listagemUnidades: [{nome_fantasia: 'Buscando...'}],
			},
			filters: {
				nome: '',
				cpf: '',
				desc_cargo: ''
			},
			snackbarColor: 'green',
			snackbarText: '',
			snackbarShower: false,

			loadingStatus: false,
			loadingStatusExport: false,
			showDialogCadastro: false,
			
			dadosEdicao: {},
			showDialogEdit: false,

			usuarioAResetar: 0,
			showDialogConfirmReset: false,

			usuarioAInativar: 0,
			showDialogConfirmInativacao: false,

			usuarioAAtivar: 0,
			showDialogConfirmAtivacao: false,

			dadosColaborador: {
				id_usuario: 0,
				nome: '',
				cpf: '',
				salario: 0,
				id_cargo_colaborador: ''
			},
			showDialogColaborador: false
		}
	},
	methods: {
		exportarListagemFiltros() {
			this.loadingStatus = true;
			var thisEl = this;

			var dataToSend = this.filters;
			dataToSend.token = localStorage.getItem('token');
			
			thisEl.$http.post(//requisição ajax
                'usuario/exportar_com_filtros',
                dataToSend,
                { emulateJSON: true }
            ).then(
                function(r){//sucesso
                	if (r.body.token == false) {
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
                    		thisEl.$router.push('/logout');
                    	}, 2000);
                    } else {
                        if (r.body.success) {
                        	if (r.body.listagemGeral.length) {
	                        	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = 'Listagem gerada!';
	                        	thisEl.snackbarShower = true;

	                        	let dados = {};

								dados.headers = Object.keys(r.body.listagemGeral[0]).map((item) => {
									return {name: item};
								});

								dados.values = r.body.listagemGeral;
								
								this.exportXlsAjax(dados, 'usuarios', []);
							} else {
								thisEl.snackbarColor = 'orange darken-1';
			      				thisEl.snackbarText = 'Sem dados para exportar!';
	                        	thisEl.snackbarShower = true;
							}
                        } else {
                        	thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
                        }
                    }
				},
                function(error){//erro na requisição, por exemplo 404
                    thisEl.loadingStatus = false;
                	thisEl.snackbarColor = 'red';
      				thisEl.snackbarText = 'Houve um erro com a requisição.';
      				thisEl.snackbarShower = true;
                }
            ).finally(function(){//executa este método após toda execução do then
                //do something
            });
		},
		exportarListagemGeral() {
			let dados = {};

			dados.headers = Object.keys(this.data.listagemGeral[0]).map((item) => {
				return {name: item};
			});

			dados.values = this.data.listagemGeral;
			
			console.log(dados);

			this.exportXlsAjax(dados, 'usuarios', []);
		},
		exportXlsAjax(dados, filename, numericHeaders){
			var thisEl = this;
            const workbook = new Excel.Workbook()//novo arquivo
            const worksheet = workbook.addWorksheet('Sheet1')//nova sheet

            var headers = [];
	        for (var i = 0; i < dados.headers.length; i++) { 
	        	headers.push(dados.headers[i].name);
	        }

	        var lines = [];
			for (var i = 0; i < dados.values.length; i++) { 
				var line = [];
				for (var ii = 0; ii < headers.length; ii++) {
					let valueToPush = eval('dados.values[i].' + headers[ii]);
					valueToPush = ((valueToPush == null) ? '' : valueToPush);

					if (numericHeaders.indexOf(headers[ii]) >= 0) {
						valueToPush = Number(valueToPush);
					}
					
					line.push(valueToPush);
				}

				lines.push(line);
			}

            //adiciona linhas na sheet
            worksheet.addRows([headers])
            worksheet.addRows(lines)

            workbook.xlsx.writeBuffer().then(function(buffer){//cria buffer do arquivo
                const data = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});//cria arquivo em Blob
                
                var link = document.createElement('a');

                link.setAttribute('href', URL.createObjectURL(data));
                link.setAttribute('download', filename + '.xlsx');
                
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                
                link.click();
                document.body.removeChild(link);
            });
        },
		submitCadastro () {
			if (this.$refs.formCadastramento.validate()) {
				
				this.loadingStatus = true;
				var thisEl = this;

				var dataToSend = this.cadastro;
				dataToSend.token = localStorage.getItem('token');

				thisEl.$http.post(//requisição ajax
                    'usuario/criar_usuario',
                    dataToSend,
                    { emulateJSON: true }
                ).then(
                    function(r){//sucesso
                    	if (r.body.token == false) {
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
                        		thisEl.$router.push('/logout');
                        	}, 2000);
                        } else {
                            if (r.body.success) {
                            	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = r.body.message;
                            	thisEl.snackbarShower = true;

                            	setTimeout(function(){
	                        		thisEl.loadingStatus = false;
	                        		thisEl.showDialogCadastro = false;

	                        		thisEl.cadastro = {
										nome: '',
										cpf: '',
										id_cargo: '',
										username: '',
										ids_unidades: [],
										registrar_colaborador: 'Não',
										salario: 0,
										id_cargo_colaborador: ''
									};
	                        	}, 1000);

	                        	this.buscaListaDefault();
                            } else {
                            	thisEl.loadingStatus = false;
                            	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
                            }
                        }
					},
                    function(error){//erro na requisição, por exemplo 404
                        thisEl.loadingStatus = false;
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = 'Houve um erro com a requisição.';
	      				thisEl.snackbarShower = true;
                    }
                ).finally(function(){//executa este método após toda execução do then
                    //do something
                });
			}
		},
		editarUsuario (dados) {
			this.dadosEdicao = {...dados};
			if (dados.ids_unidades) {
				this.dadosEdicao.ids_unidades = dados.ids_unidades.split(',');//converte string separa por virgula em array
			} else {
				this.dadosEdicao.ids_unidades = [];
			}
			this.showDialogEdit = true;
		},
		submitEdicao () {
			if (this.$refs.formEdicao.validate()) {
				
				this.loadingStatus = true;
				var thisEl = this;

				var dataToSend = this.dadosEdicao;
				dataToSend.token = localStorage.getItem('token');

				thisEl.$http.post(//requisição ajax
                    'usuario/atualizar_usuario',
                    dataToSend,
                    { emulateJSON: true }
                ).then(
                    function(r){//sucesso
                    	if (r.body.token == false) {
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
                        		thisEl.$router.push('/logout');
                        	}, 2000);
                        } else {
                            if (r.body.success) {
                            	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = r.body.message;
                            	thisEl.snackbarShower = true;

                            	setTimeout(function(){
                            		thisEl.loadingStatus = false;
	                        		thisEl.showDialogEdit = false;
	                        	}, 1000);

                            	this.buscaListaDefault();
                            } else {
                            	thisEl.loadingStatus = false;
                            	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
                            }
                        }
					},
                    function(error){//erro na requisição, por exemplo 404
                        thisEl.loadingStatus = false;
                    	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = 'Houve um erro com a requisição.';
	      				thisEl.snackbarShower = true;
                    }
                ).finally(function(){//executa este método após toda execução do then
                    //do something
                });
			}
		},
		confirmaResetSenha (id_usuario) {
			this.usuarioAResetar = id_usuario;
			this.showDialogConfirmReset = true;
		},
		efetuarResetSenhaUsuario () {
			this.loadingStatus = true;

			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'usuario/resetar_senha_usuario',
	            {
	            	token: localStorage.getItem('token'),
	            	id_usuario: thisEl.usuarioAResetar
	           	},
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.loadingStatus = false;
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	if (r.body.success) {
                        	thisEl.snackbarColor = 'green';
		      				thisEl.snackbarText = r.body.message;
                        	thisEl.snackbarShower = true;

                        	setTimeout(function(){
                        		thisEl.loadingStatus = false;
                        		thisEl.showDialogConfirmReset = false;
                        	}, 1000);

                        	this.buscaListaDefault();
                        } else {
                        	thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
                        }
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		confirmaInativacaoUsuario (id_usuario) {
			this.usuarioAInativar = id_usuario;
			this.showDialogConfirmInativacao = true;
		},
		efetuarInativacaoUsuario () {
			this.loadingStatus = true;

			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'usuario/inativar_usuario',
	            {
	            	token: localStorage.getItem('token'),
	            	id_usuario: thisEl.usuarioAInativar
	           	},
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.loadingStatus = false;
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	if (r.body.success) {
                        	thisEl.snackbarColor = 'green';
		      				thisEl.snackbarText = r.body.message;
                        	thisEl.snackbarShower = true;

                        	setTimeout(function(){
                        		thisEl.loadingStatus = false;
                        		thisEl.showDialogConfirmInativacao = false;
                        	}, 1000);

                        	this.buscaListaDefault();
                        } else {
                        	thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
                        }
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		confirmaAtivacaoUsuario (id_usuario) {
			this.usuarioAAtivar = id_usuario;
			this.showDialogConfirmAtivacao = true;
		},
		efetuarAtivacaoUsuario () {
			this.loadingStatus = true;

			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'usuario/ativar_usuario',
	            {
	            	token: localStorage.getItem('token'),
	            	id_usuario: thisEl.usuarioAAtivar
	           	},
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.loadingStatus = false;
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	if (r.body.success) {
                        	thisEl.snackbarColor = 'green';
		      				thisEl.snackbarText = r.body.message;
                        	thisEl.snackbarShower = true;

                        	setTimeout(function(){
                        		thisEl.loadingStatus = false;
                        		thisEl.showDialogConfirmAtivacao = false;
                        	}, 1000);

                        	this.buscaListaDefault();
                        } else {
                        	thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
                        }
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		cadastrarColaborador (dados) {
			this.dadosColaborador.id_usuario = dados.id_usuario;
			this.dadosColaborador.nome = dados.nome;
			this.dadosColaborador.cpf = dados.cpf;

			this.showDialogColaborador = true;
		},
		efetuarCadastroUsuarioComoColaborador () {
			if (this.$refs.formCadastramentoColaborador.validate()) {
				this.loadingStatus = true;

				var thisEl = this;

				var dataToSend = this.dadosColaborador;
				dataToSend.token = localStorage.getItem('token');

				thisEl.$http.post(//requisição ajax
		            'usuario/cadastrar_usuario_como_colaborador',
		            dataToSend,
		            { emulateJSON: true }
		        ).then(
		            function(r){//sucesso
		                if (r.body.token == false) {
		                	thisEl.loadingStatus = false;
		                	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
		                		thisEl.$router.push('/logout');
		                	}, 2000);
		                } else {
		                	if (r.body.success) {
	                        	thisEl.snackbarColor = 'green';
			      				thisEl.snackbarText = r.body.message;
	                        	thisEl.snackbarShower = true;

	                        	setTimeout(function(){
	                        		thisEl.loadingStatus = false;
	                        		thisEl.showDialogColaborador = false;

	                        		thisEl.dadosColaborador = {
										id_usuario: 0,
										nome: '',
										cpf: '',
										salario: 0,
										id_cargo_colaborador: ''
									};
	                        	}, 1000);

	                        	this.buscaListaDefault();
	                        } else {
	                        	thisEl.loadingStatus = false;
	                        	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
	                        }
		                }
					},
		            function(error){//erro na requisição, por exemplo 404
		                thisEl.loadingStatus = false;
		            	thisEl.snackbarColor = 'red';
		  				thisEl.snackbarText = 'Houve um erro com a requisição.';
		  				thisEl.snackbarShower = true;
		            }
		        );
		    }
		},
		limparFiltro() {
			var thisEl = this;
			Object.keys(thisEl.filters).forEach(function(key) {
				thisEl.filters[key] = '';
			});
		},
		buscaListaDefault() {
			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'usuario/listagem_usuarios',
	            { token: localStorage.getItem('token') },
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemGeral = r.body.listagemGeral;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		},
		buscaFiltros() {
			var thisEl = this;
			thisEl.$http.post(//requisição ajax
	            'usuario/listagem_filtros',
	            { token: localStorage.getItem('token') },
	            { emulateJSON: true }
	        ).then(
	            function(r){//sucesso
	                if (r.body.token == false) {
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = r.body.message;
	      				thisEl.snackbarShower = true;
	      				
	      				setTimeout(function(){
	                		thisEl.$router.push('/logout');
	                	}, 2000);
	                } else {
	                	thisEl.data.listagemCargos = r.body.listagemCargos;
	                	thisEl.data.listagemCargosColaboradores = r.body.listagemCargosColaboradores;
	                	thisEl.data.listagemUnidades = r.body.listagemUnidades;
	                }
				},
	            function(error){//erro na requisição, por exemplo 404
	                thisEl.loadingStatus = false;
	            	thisEl.snackbarColor = 'red';
	  				thisEl.snackbarText = 'Houve um erro com a requisição.';
	  				thisEl.snackbarShower = true;
	            }
	        );
		}
	},
	computed: {
		cargosParaCriacao() {
			var ids = [];
			this.data.listagemCargos.forEach(function(item, key){
				if (item.id_cargo != 1) {
					ids.push(item);
				}
			});
			return ids;
		},
		listagemHeaders() {
			return [
				{ text: '', value: 'actions', class: 'blue darken-4 white--text' },
				{
					text: 'ID Usuário',
					value: 'id_usuario',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Status',
					value: 'status',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Colaborador',
					value: 'id_colaborador',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Nome',
					value: 'nome',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.nome) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.nome.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'CPF',
					value: 'cpf',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.cpf) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.cpf.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Cargo',
					value: 'desc_cargo',
					class: 'blue darken-4 white--text',
					filter: (value) => {
						if (!this.filters.desc_cargo) {
							return true;
						}

						return (value.toUpperCase().indexOf(this.filters.desc_cargo.toUpperCase()) >= 0) ? true : false;//pesquisa string dentro de outra string
					}
				},
				{
					text: 'Username',
					value: 'username',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Unidades Operacionais',
					value: 'unidades',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data Criação',
					value: 'dt_criacao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Usuário Criador',
					value: 'nome_usuario_criador',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Data Alteração',
					value: 'dt_alteracao',
					class: 'blue darken-4 white--text'
				},
				{
					text: 'Usuário Alterador',
					value: 'nome_usuario_alterador',
					class: 'blue darken-4 white--text'
				},
			];
		}
	},
	mounted() {
		this.buscaListaDefault();
		this.buscaFiltros();
	}
};
</script>
